.content-container {
    margin: 0 auto;
    padding: 0 1.2rem;
    max-width: 95%;
}

.header {
    background: #FFFFFF;
    border-bottom: 2px solid black;
}

.header__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2.5rem 0;
}

.header__title {
    color: white;
    text-decoration: none;
    margin: 0;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .content-container {
        padding: 0;
        max-width: 100%;
    }
    .header__content {
        padding: 2.5rem 1.2rem;
    }
}