.mapContainer {
    width: 100%;
    height:90vh;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .mapContainer {
        width: 100%;
        height:89vh;
    }
}